import * as OfferService from "@services/offer.service";
import { useInquiry } from "@store/wizard-inqury";
import { useMutation } from "@tanstack/react-query";
import { trackPlaceOrder } from "@utils/tracking";
import { t } from "i18next";
import { toast } from "sonner";

export const usePlaceOrder = ({ userId }: { userId: string }) => {
  return useMutation({
    mutationFn: OfferService.acceptOfferProcess,
    onSuccess: ({ data, error }) => {
      if (error) {
        toast.error(t("error_form-general"));
        console.error(error);
        return;
      }
      if (!data) return;
      trackPlaceOrder({
        orderId: data.orderId || data.frameworkAgreementId || "",
        userId: userId || "",
      });
      useInquiry.getState().setCurrentStep("3");
      useInquiry.getState().setStepStatus("2", true);
      useInquiry.getState().setOrderStatePayload(data);
    },
  });
};
